import { Navigate } from "react-router-dom"
import {
  ChoosePricingPage,
  CompleteInvitationPage,
  CreateAccountPage,
  LoginPage,
  ManageTeamPage,
  MatchesPage,
  RequirementsPage,
  ResumePage,
  SettingsPage,
  PaymentPage,
  PaymentConfirmationPage,
  TermsOfUse,
  BuyMorePage,
  PendingPaymentPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  SelectCVPage,
  AddJobSpecPage,
  CompanyInfoPage,
  PlanAndBillingPage,
  ChangePlanPage,
  ATSIntegrationPage,
  ImportCVPage,
  FreeTrialSignupPage,
  JobCandidatesPage,
} from "../components"
import FreemiumConfirmationPage from "../components/views/FreemiumConfirmationPage"
import { ROUTES } from "../constants/routes"

export const UNPROTECTED_ROUTES = [
  {
    path: "/terms-of-use",
    El: <TermsOfUse />,
    layoutProps: {
      showNavbarArt: false,
      navBackground: true,
      allowAuthenticatedUsers: true,
    },
  },
  {
    path: "/login",
    El: <LoginPage />,
    layoutProps: {
      showNavbarArt: false,
      navBackground: true,
    },
  },
  {
    path: "/forgot-password",
    El: <ForgotPasswordPage />,
    layoutProps: {
      showNavbarArt: false,
      navBackground: true,
    },
  },
  {
    path: "/reset-password",
    El: <ResetPasswordPage />,
    layoutProps: {
      showNavbarArt: false,
      navBackground: true,
    },
  },
  {
    path: "/invitation/complete-invitation",
    El: <CompleteInvitationPage />,
    layoutProps: {
      showNavbarArt: false,
      navBackground: true,
    },
  },
]

export const PROTECTED_ROUTES_ORGANIZATION = [
  {
    path: "/resume/:key",
    El: <ResumePage />,
  },
  {
    path: "/job-match/select-cv",
    El: <SelectCVPage />,
  },
  {
    path: "/job-match/select-cv/:key",
    El: <ResumePage backButton={"/job-match/select-cv"} showCheckbox={true} />,
  },
  {
    path: "/job-match/job-spec",
    El: <AddJobSpecPage />,
  },
  {
    path: "/job-match/:jobId/requirements",
    El: <RequirementsPage />,
  },
  {
    path: "/job-match/:jobId/matches",
    El: <MatchesPage />,
  },
  {
    path: "/job-match/:jobId/candidates",
    El: <JobCandidatesPage />,
  },
  {
    path: "/job-match/:jobId/candidates/:key",
    El: (
      <ResumePage
        backButton={"/job-match/:jobId/candidates"}
        showCheckbox={false}
      />
    ),
  },
  {
    path: "/job-match/matches",
    El: <MatchesPage />,
  },
  {
    path: "/manage-team",
    El: <ManageTeamPage />,
    roles: ["TeamOwner"],
    hideForTrialingUsers: true,
  },
  {
    path: "/settings",
    El: <SettingsPage />,
  },
  {
    path: "/plan-and-billing",
    El: <PlanAndBillingPage />,
    hideForTrialingUsers: true,
  },
  {
    path: "/buy-more",
    El: <BuyMorePage />,
    hideForTrialingUsers: true,
  },
  {
    path: "/change-plan",
    El: <ChangePlanPage />,
    hideForTrialingUsers: true,
  },
  {
    path: "/ats-integration",
    El: <ATSIntegrationPage />,
    roles: ["TeamOwner"],
    hideForTrialingUsers: true,
  },
  {
    path: "/import-cvs",
    El: <ImportCVPage />,
    hideForTrialingUsers: true,
  },
  {
    path: "/freemium-confirmation",
    El: <FreemiumConfirmationPage />,
  },
  {
    path: "*",
    El: <Navigate to={"/job-match/select-cv"} />,
  },
]

export const CREATE_ACCOUNT_ROUTES = [
  {
    path: "/create-account",
    El: <CreateAccountPage />,
  },
  {
    path: ROUTES.FREEMIUM_SIGNUP,
    El: <FreeTrialSignupPage />,
  },
  {
    path: "/company-info",
    El: <CompanyInfoPage />,
  },
  {
    path: "/choose-pricing",
    El: <ChoosePricingPage />,
  },
  {
    path: "/payment",
    El: <PaymentPage />,
  },
  {
    path: "/payment-confirmation",
    El: <PaymentConfirmationPage />,
  },
  {
    path: "/pending-payment",
    El: <PendingPaymentPage />,
  },
]
