import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import debounce from "lodash.debounce"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { useCookies } from "react-cookie"
import { toast } from "react-toastify"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Checkbox,
  SignupInput,
  PhoneNumberInput,
  CustomButton,
} from "../../common"

import { freeTrialSignup, isPhoneNumberFree } from "../../../services/auth"
import { loginAction } from "../../../redux/slices/authSlice"
import { resetCreditSlice } from "../../../redux/slices/creditSlice"
import { resetIntegrationSlice } from "../../../redux/slices/integrationSlice"
import { resetJobSlice } from "../../../redux/slices/jobSlice"

import { TOKEN_DURATION } from "../../../constants"
import { GA_EVENTS } from "../../../constants/analytics"
import {
  COMPANY_NAME_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
  // emailBlacklistValidation
} from "../../../util/formValidation"

const FreeTrialSignupPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie, __] = useCookies(["token"])

  const [loading, setLoading] = useState(false)
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const jobSearchQuantity = searchParams.get("jobMatches")

  const auth = useSelector((state) => state.auth)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    getValues,
    setError,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      email: "",
      password: "",

      company: "",
      countryCode: "",
      phoneNumber: "",
      agreed: false,
    },
  })

  watch([
    "name",
    "email",
    "password",
    "company",
    "countryCode",
    "phoneNumber",
    "agreed",
  ])

  const values = getValues()

  // Debounced useCallback function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(
    debounce((values) => {
      setIsValidPhoneNumber(false)
      isPhoneNumberFree({
        phoneNumber: values.phoneNumber,
        countryCode: values.countryCode,
      })
        .then(() => {
          setIsValidPhoneNumber(true)
        })
        .catch(() => {
          setError("phoneNumber", {
            message: formatMessage({
              id: "common.phoneNumberAlreadyRegistered",
            }),
          })
          setIsValidPhoneNumber(false)
        })
    }, 500),
    [],
  )

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/job-match/select-cv")
    }

    setIsValidPhoneNumber(false)
    if (!values.phoneNumber || !values.countryCode?.label) return
    debouncedCallback({
      phoneNumber: values.phoneNumber,
      countryCode: values.countryCode?.label,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.phoneNumber,
    values.countryCode?.label,
    debouncedCallback,
    navigate,
  ])

  const onFormSubmit = (values) => {
    if (!isValidPhoneNumber) {
      return
    }
    // Validate Phone Number
    if (!values.countryCode?.value) {
      return setError("countryCode", {
        message: formatMessage({
          id: "common.countryCodeIsRequired",
        }),
      })
    }

    setLoading(true)
    freeTrialSignup({
      email: values.email,
      name: values.name,
      password: values.password,
      phoneNumber: values.phoneNumber,
      jobSearchQuantity,

      country: values.countryCode.value,
      countryCode: values.countryCode.label,
      company: values.company,
    })
      .then((data) => {
        dispatch(resetCreditSlice())
        dispatch(resetIntegrationSlice())
        dispatch(resetJobSlice())

        dispatch(loginAction(data))
        setCookie("token", data.token, {
          path: "/",
          expires: new Date(Date.now() + TOKEN_DURATION),
        })

        navigate(
          data.subscriptionId ? "/pending-payment" : "/freemium-confirmation",
        )
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            formatMessage({
              id: "toast.somethingWentWrong",
            }),
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const showCompanyInput = values.name && values.email && values.password

  return (
    <div className="create-account">
      <div className="create-account__wrapper">
        <h1 className="create-account__title mb-15">
          <FormattedMessage id="common.welcomeToFribl" />
        </h1>

        <div className="create-account__content">
          <div className="create-account__inputs">
            <SignupInput
              register={register("name", {
                required: formatMessage({ id: "common.nameRequired" }),
                pattern: {
                  value: NAME_REGEX,
                  message: formatMessage({
                    id: "message.enterBothFirstAndLastName",
                  }),
                },
              })}
              placeholder={formatMessage({ id: "common.yourName" })}
              error={errors.name?.message}
              touched={touchedFields.name}
              value={values.name}
              label={formatMessage({ id: "common.name" })}
            />

            <SignupInput
              register={register("email", {
                required: formatMessage({ id: "common.emailRequired" }),
                pattern: {
                  value: EMAIL_REGEX,
                  message: formatMessage({
                    id: "common.somethingDoesntLookRight",
                  }),
                },

                // TODO: Uncomment this when we want to blacklist personal emails
                // validate: (value) => emailBlacklistValidation(value, formatMessage({
                //   id: "message.notGmailOrPersonal",
                // })),
              })}
              placeholder={formatMessage({ id: "common.workEmail" })}
              error={errors.email?.message}
              touched={touchedFields.email}
              value={values.email}
              helperText={formatMessage({ id: "message.notGmailOrPersonal" })}
              type="email"
              label={formatMessage({ id: "common.workEmail" })}
            />

            <SignupInput
              register={register("password", {
                required: formatMessage({ id: "common.passwordRequired" }),
                pattern: {
                  value: PASSWORD_REGEX,
                  message: formatMessage({
                    id: "message.checkPasswordRequirements",
                  }),
                },
              })}
              placeholder={formatMessage({ id: "common.chooseYourPassword" })}
              error={errors.password?.message}
              touched={touchedFields.password}
              value={values.password}
              type="password"
              helperText={formatMessage({
                id: "message.passwordSafetyRequirement",
              })}
            />

            {showCompanyInput && (
              <>
                <SignupInput
                  register={{
                    ...register("company", {
                      required: formatMessage({
                        id: "common.companyNameIsRequired",
                      }),
                      pattern: {
                        value: COMPANY_NAME_REGEX,
                        message: formatMessage({
                          id: "common.somethingDoesntLookRight",
                        }),
                      },
                    }),
                  }}
                  placeholder={formatMessage({
                    id: "common.whatsYourCompanyName",
                  })}
                  error={errors.company?.message}
                  touched={touchedFields.company}
                  value={values.company}
                  label={formatMessage({ id: "common.company" })}
                />

                {values.company && (
                  <>
                    <PhoneNumberInput
                      errors={errors}
                      isValid={isValidPhoneNumber}
                      touchedFields={touchedFields}
                      phoneNumberRegister={register("phoneNumber", {
                        required: formatMessage({
                          id: "common.phoneNumberRequired",
                        }),
                        // Add validation to include only numbers
                        pattern: {
                          value: PHONE_NUMBER_REGEX,
                          message: formatMessage({
                            id: "common.somethingDoesntLookRight",
                          }),
                        },
                      })}
                      control={control}
                      values={values}
                      label={formatMessage({ id: "common.phoneNumber" })}
                    />

                    <Checkbox
                      register={register("agreed", {
                        required: formatMessage({
                          id: "common.youNeedToAgree",
                        }),
                        validate: (value) => value === true,
                      })}
                      label={
                        <>
                          <FormattedMessage id="message.confirmReadAgreed" />{" "}
                          <a
                            target={"_blank"}
                            rel={"noreferrer"}
                            href={"/terms-of-use"}
                          >
                            <FormattedMessage id="common.termsAndConditionsAndPrivacyPolicies" />
                          </a>
                        </>
                      }
                      value={values.agreed}
                      error={errors.agreed?.message}
                    />
                  </>
                )}
              </>
            )}
          </div>

          {showCompanyInput && values.company && (
            <CustomButton
              className={classNames("btn btn--small primary mt-15", {
                disabled: loading || !isValid || !isValidPhoneNumber,
              })}
              disabled={loading}
              onClick={handleSubmit(onFormSubmit)}
              trackingEvent={GA_EVENTS.USER_REGISTRATION.FREE_TRIAL_CONTINUE}
            >
              <FormattedMessage id="common.continue" />
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  )
}

export default FreeTrialSignupPage
