// components/Page2.js
import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { useIntl, FormattedMessage } from "react-intl"
import { toast } from "react-toastify"

import {
  setAreRequirementsSend,
  setJobs,
  setRequirements,
  setSelectedCVs,
  setTitle,
} from "../../../redux/slices/jobSlice"

import { getJobDetail, postMatchRequirements } from "../../../services/jobs"
import { CustomLink, MaxCandidateSelect } from "../../common"
import { Requirements, Weights } from "./components"
import { GA_EVENTS } from "../../../constants/analytics"

const FIFTEEN_SECONDS_IN_MS = 15_000

const RequirementsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const params = useParams()
  const { jobId: jobID } = params

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const isFetchingJobRef = useRef(false)
  const [weightsData, setWeightsData] = useState({})
  const [data, setData] = useState(null)

  const credit = useSelector((state) => state.credit)
  const title = useSelector((state) => state.jobs.title)
  const { alreadyMatchedOnce, selectedCVs } = useSelector((state) => state.jobs)

  useEffect(() => {
    if (!jobID) {
      navigate("/job-match/select-cv")
    }
  }, [jobID, navigate])

  useEffect(() => {
    if (jobID) {
      setLoading(true)
      // try every 2 seconds to get the requirements

      const max_tries = 100
      let tries = 0

      const fetchJobDetailRecursive = async () => {
        tries += 1
        try {
          let { data: jobData } = await getJobDetail({
            ad_id: jobID,
          })

          if (jobData) {
            const filteredMustHaves = jobData.job_requirements

            dispatch(setSelectedCVs(jobData.selected_candidates))

            dispatch(
              setRequirements({
                jobId: jobID,
                requirements: filteredMustHaves,
              }),
            )
            setData(jobData)
            dispatch(setTitle(jobData.information.title))
            setLoading(false)
            setWeightsData({
              hard_skills_weight: 0.25,
              soft_skills_weight: 0.25,
              education_weight: 0.25,
              experience_weight: 0.25,
            })
            isFetchingJobRef.current = false
          }
        } catch (err) {
          if (tries > max_tries) {
            // Navigate to job match select cv page
            navigate("/job-match/select-cv")
            setLoading(false)
          } else {
            setTimeout(fetchJobDetailRecursive, FIFTEEN_SECONDS_IN_MS)
          }
        }
      }
      if (!isFetchingJobRef.current) {
        isFetchingJobRef.current = true
        fetchJobDetailRecursive()
      }
    }
  }, [jobID, dispatch, navigate])

  const clearSelectedCVs = () => dispatch(setSelectedCVs([]))

  const handleClick = async (maxCandidates, threshold) => {
    if (!maxCandidates && !threshold) return

    setSubmitLoading(true)

    let data_api = {
      ad_id: jobID,
      weights: weightsData,
    }

    const filteredCVs = selectedCVs.filter(Boolean)
    if (!filteredCVs.length) {
      toast.error(formatMessage({ id: "toast.errorSelectingCandidates" }))
      setSubmitLoading(false)
      return
    }

    if (threshold) {
      data_api.job_meta = {
        threshold,
      }

      data_api.max_candidates = filteredCVs.length
    } else {
      data_api.max_candidates = maxCandidates + 15
      data_api.job_meta = {
        actual_topK: maxCandidates,
      }
    }

    data_api.candidate_ids = filteredCVs
    // send a post request to the API; data_api is the data to be sent
    await postMatchRequirements(data_api)

    dispatch(setAreRequirementsSend(true))

    setSubmitLoading(false)
    navigate(`/job-match/${jobID}/matches`)
    // Clear selected CVs
    clearSelectedCVs()

    // Clear jobs
    dispatch(setJobs(null))
  }

  if (loading) {
    return (
      <div className="requirements-page">
        <div className="step">
          <FontAwesomeIcon icon={faCog} spin />{" "}
          <FormattedMessage id="common.loading" />
        </div>
      </div>
    )
  }

  if (submitLoading) {
    return (
      <div className="requirements-page">
        <div className="step">
          <FontAwesomeIcon icon={faCog} spin />{" "}
          <FormattedMessage id="common.processing" />
          (<FormattedMessage id="common.processingTime" />)
        </div>
      </div>
    )
  }

  return (
    <div className="requirements-page">
      {!submitLoading ? (
        <div className="requirements-page__content">
          <div className="requirements-page__header">
            <h3 className="requirements-page__title h3">
              <FormattedMessage id="message.checkThroughMessage" />{" "}
              <span>
                <FormattedMessage id="message.skillsForRole" />
              </span>
            </h3>
            <p className="p">
              <FormattedMessage id="message.calculateScoresMessage" />
            </p>
          </div>

          <div className="requirements-page__topbar">
            <p className="requirements-page__">{`${title} - ${data?.experience_preference?.activity_sector}`}</p>
          </div>

          {alreadyMatchedOnce && (
            <p className="requirements-page__info">
              <FormattedMessage id="message.updateRequirementsMessage" />
              <CustomLink
                to={`/job-match/${jobID}/matches`}
                trackingEvent={GA_EVENTS.REQUIREMENTS.CANCEL_AND_BACK}
              >
                <FormattedMessage id="common.cancelAndGoBack" />
              </CustomLink>
            </p>
          )}

          {data && <Requirements data={data} />}
          {credit.cvCredits === 0 && (
            <p className="requirements-page__info">
              <FormattedMessage id="message.youHaveUsedYourTokens" />

              {/* // TODO: Uncomment this when Premium implementation is available */}
              {/* <CustomLink
                to={"/buy-more"}
                trackingEvent={GA_EVENTS.REQUIREMENTS.ADD_MORE_CREDITS}
              >
                <FormattedMessage id="common.addMoreHere" />
              </CustomLink> */}
            </p>
          )}
          <Weights
            weightsData={weightsData}
            setWeightsData={setWeightsData}
            action={
              <MaxCandidateSelect
                disabled={Boolean(!credit.cvCredits)}
                onClick={(val, threshold) => handleClick(val, threshold)}
              />
            }
          />
        </div>
      ) : (
        <div className="step">
          <FontAwesomeIcon icon={faCog} spin />{" "}
          <FormattedMessage id="common.processing" />
          (<FormattedMessage id="common.processingTime" />)
        </div>
      )}
    </div>
  )
}

export default RequirementsPage
