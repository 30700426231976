import React from "react"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { logoutAction } from "../../../redux/slices/authSlice"
import { useLocation } from "react-router-dom"
import classNames from "classnames"

import { GA_EVENTS } from "../../../constants/analytics"
import CustomLink from "../CustomLink"
import CustomNavLink from "../CustomNavLink"
import { FormattedMessage, useIntl } from "react-intl"

const Navbar = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, __, removeCookie] = useCookies(["token"])

  const { pathname } = useLocation()
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleLogout = () => {
    removeCookie("token", {
      path: "/",
    })
    dispatch(logoutAction())
  }

  const isFreemium = auth.onboardingStage === "Trialing"

  const menuItems = []

  if (auth.role?.role === "TeamOwner") {
    menuItems.push({
      name: formatMessage({ id: "sidebar.teamMembers" }),
      type: "link",
      to: "/manage-team",
    })
  }

  menuItems.push(
    {
      name: formatMessage({ id: "navbar.settings" }),
      type: "link",
      to: "/settings",
    },
    {
      name: formatMessage({ id: "navbar.logout" }),
      type: "button",
      onClick: handleLogout,
    },
  )

  return (
    <div className="navbar">
      <div className="navbar__wrapper">
        <div className="navbar__menus">
          <CustomLink
            to={"/job-match/select-cv"}
            trackingEvent={GA_EVENTS.NAV.SELECT_CV}
          >
            <FormattedMessage id="navbar.candidateCVs" />
          </CustomLink>
          <CustomLink
            className={classNames({ selected: pathname.includes("/matches") })}
            to={"/job-match/matches"}
            trackingEvent={GA_EVENTS.NAV.JOB_MATCHES}
          >
            <FormattedMessage id="navbar.jobMatches" />
          </CustomLink>
          {!isFreemium && (
            <CustomNavLink
              className={({ isActive }) => classNames({ selected: isActive })}
              to={"/buy-more"}
              trackingEvent={GA_EVENTS.NAV.BUY_MORE}
            >
              <FormattedMessage id="navbar.buyAddOns" />
            </CustomNavLink>
          )}
          <CustomLink
            to={"/job-match/select-cv"}
            className={classNames({
              selected:
                pathname.includes("job-match") && !pathname.includes("matches"),
            })}
            trackingEvent={GA_EVENTS.NAV.NEW_JOB_MATCH}
          >
            <FormattedMessage id="navbar.newJobMatch" />
          </CustomLink>
        </div>
      </div>
    </div>
  )
}

export default Navbar
