import React, { useContext, useEffect, useState } from "react"
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import { useCookies } from "react-cookie"
import { useIntl } from "react-intl"

import "react-toastify/dist/ReactToastify.css"

import {
  AuthenticatedLayout,
  BasicLayout,
  CreateAccountLayout,
} from "./Layouts"
import "./styles/main.scss"
import {
  PROTECTED_ROUTES_ORGANIZATION,
  UNPROTECTED_ROUTES,
  CREATE_ACCOUNT_ROUTES,
} from "./routes"
import { me } from "./services/auth"
import { loginAction } from "./redux/slices/authSlice"
import { getCredits } from "./services/creditService"
import { setCredits } from "./redux/slices/creditSlice"
import { ReactGA } from "./components"
import { LanguageContext } from "./i18n/LanguageProvider"
import { getLanguage } from "./services/team"

const DEFAULT_LANGUAGE = "en";

const queryClient = new QueryClient();

function App() {
  const { formatMessage } = useIntl();
  const [cookies, setCookie, removeCookie] = useCookies(["token"])
  const [init, setInit] = useState(false)
  const dispatch = useDispatch()
  const { language, prevLanguage, changeLanguage } = useContext(LanguageContext)

  const auth = useSelector((state) => state.auth)

  const PROTECTED_ROUTES = PROTECTED_ROUTES_ORGANIZATION

  const navigate = useNavigate();

  // Show toast when language is updated
  useEffect(() => {
    if (prevLanguage && language !== prevLanguage) {
      toast.success(
        formatMessage({ id: "toast.languageUpdatedSuccessfully" }),
      )
    }
  }, [language, prevLanguage, formatMessage]);

  useEffect(() => {
    if (cookies.token) {
      me({ token: cookies.token })
        .then((data) => {
          dispatch(loginAction(data))
          setCookie("token", data.token, {
            path: "/",
            expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          })

          if (data.data.onboardingStage === "AccountCreation") {
            navigate("/company-info")
          }

          if (data.data.onboardingStage === "ChoosePrice") {
            navigate("/choose-pricing")
          }

          if (data.data.onboardingStage === "PaymentPending") {
            navigate("/pending-payment")
          }

          setInit(true)
        })
        .catch(() => {
          removeCookie("token", {
            path: "/",
          })
          setInit(true)
        })
    } else {
      setInit(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      ["Complete", "Trialing"].includes(auth.onboardingStage)
    ) {
      getCredits().then((credits) => {
        dispatch(setCredits(credits))
      })
    }
  }, [auth.isAuthenticated, auth.onboardingStage, dispatch, changeLanguage])

  useEffect(() => {
    if (auth.isAuthenticated) {
      // Load language from user setting
      getLanguage()
        .then((data) => changeLanguage(data.language))
        .catch((error) => {
          console.error(error)
          changeLanguage(DEFAULT_LANGUAGE)
        });
    }
  }, [auth.isAuthenticated, changeLanguage])
  
  if (!init) return true

  return (
    <QueryClientProvider client={queryClient}>
      <ReactGA />

      <Routes>
        {UNPROTECTED_ROUTES.map(({ path, El, layoutProps = {} }) => (
          <Route
            key={path}
            path={path}
            element={<BasicLayout {...layoutProps}>{El}</BasicLayout>}
          />
        ))}
        {CREATE_ACCOUNT_ROUTES.map(({ path, El, layoutProps = {} }) => (
          <Route
            key={path}
            path={path}
            element={
              <CreateAccountLayout {...layoutProps}>{El}</CreateAccountLayout>
            }
          />
        ))}
        {PROTECTED_ROUTES?.map(
          ({
            path,
            El,
            roles,
            Sidebar,
            allowOnboardingUsers,
            hideForTrialingUsers,
          }) => (
            <Route
              key={path}
              path={path}
              element={
                <AuthenticatedLayout
                  Sidebar={Sidebar}
                  roles={roles}
                  allowOnboardingUsers={allowOnboardingUsers}
                  hideForTrialingUsers={hideForTrialingUsers}
                >
                  {El}
                </AuthenticatedLayout>
              }
            />
          ),
        )}
      </Routes>
      <ToastContainer />
    </QueryClientProvider>
  )
}

export default App
