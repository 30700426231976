import classNames from "classnames"
import React, { useState } from "react"
import Modal from "../Modal"
import { clamp } from "../../../util/util"
import { useNavigate, useParams } from "react-router-dom"
import CustomButton from "../CustomButton"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage } from "react-intl"

const topCandidateOptions = [1, 3, 5, 10, 15, 20]

const MaxCandidateSelect = ({ onClick, disabled }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [topK, setTopK] = useState(
    localStorage.getItem("threshold")
      ? null
      : Number(localStorage.getItem("topK") || 5),
  )
  const [isSelectCandidatesByScore, setIsSelectCandidatesByScore] = useState(
    localStorage.getItem("threshold") ? true : false,
  )
  const [threshold, setThreshold] = useState(
    Number(localStorage.getItem("threshold") || 8),
  )

  const navigate = useNavigate()
  const params = useParams()

  const jobID = params.jobId

  const handleTopKClick = (value) => {
    setIsSelectCandidatesByScore(false)
    setTopK(value)
  }

  const handleMatchByThresholdClick = () => {
    setIsSelectCandidatesByScore(true)
    setTopK(null)
    setThreshold(8)
  }

  const handleSubmit = () => {
    onClick(
      isSelectCandidatesByScore ? null : topK,
      isSelectCandidatesByScore ? threshold : null,
    )

    if (isSelectCandidatesByScore) {
      localStorage.removeItem("topK")
      localStorage.setItem("threshold", threshold)
    } else {
      localStorage.removeItem("threshold")
      localStorage.setItem("topK", topK)
    }
    setIsOpen(false)
  }

  return (
    <>
      <div className={classNames("max-candidate-select")}>
        <CustomButton
          className={classNames(
            "btn primary btn--small max-candidate-select__button",
          )}
          onClick={() => {
            if (disabled) {
              navigate(`/job-match/${jobID}/matches`)
            } else {
              setIsOpen(true)
            }
          }}
          trackingEvent={GA_EVENTS.REQUIREMENTS.FIND_TOP_MATCHING}
        >
          <FormattedMessage id="common.findTopMatchingCandidates" />
        </CustomButton>
      </div>

      <Modal
        open={isOpen}
        setOpen={setIsOpen}
        closeOnOutsideClick
        className={"max-candidate-select"}
      >
        <div className="max-candidate-select__content">
          <div>
            <p className="max-candidate-select__content_title h4">
              <FormattedMessage id="common.numberOfMatches" />
            </p>
            <p className="max-candidate-select__content_description p">
              <FormattedMessage id="common.howManyTopScoringCandidates" />
            </p>
            {/*  */}
            <div className="max-candidate-select__buttons">
              {topCandidateOptions.map((option) => (
                <CustomButton
                  key={option}
                  className={classNames("max-candidate-select__button-base p", {
                    selected: topK === option,
                  })}
                  onClick={() => handleTopKClick(option)}
                >
                  <FormattedMessage id="common.top" /> {option}
                </CustomButton>
              ))}

              {/* // TODO: This feature is buggy on the matching side, so we are removing it for now */}
              {/* <div className="max-candidate-select__buttons m-0">
                <CustomButton
                  className={classNames("max-candidate-select__button-base p", {
                    selected: isSelectCandidatesByScore,
                  })}
                  onClick={handleMatchByThresholdClick}
                >
                  <FormattedMessage id="common.allCandidatesWhoScoreHigherThan" />
                </CustomButton>
                {isSelectCandidatesByScore && (
                  <input
                    type="number"
                    className="max-candidate-select__input_base"
                    value={threshold}
                    onChange={(e) =>
                      setThreshold(clamp(e.target.value, [0, 10]))
                    }
                    onBlur={() => !threshold && setThreshold(8)}
                  />
                )}
              </div> */}
            </div>

            {/*  */}

            {/* // TODO: This feature is buggy on the matching side, so we are removing it for now */}
            {/* {isSelectCandidatesByScore && (
              <p className="max-candidate-select__content_warning p">
                <FormattedMessage id="message.friblWillAlwaysShowAtLeastTop3Scorers" />
              </p>
            )} */}

            <div className="max-candidate-select__actions">
              <CustomButton
                className={classNames("btn primary btn--small", {
                  disabled: isSelectCandidatesByScore ? !threshold : !topK,
                })}
                onClick={handleSubmit}
                trackingEvent={GA_EVENTS.REQUIREMENTS.MATCHES_NUMBER_CONFIRM}
              >
                <FormattedMessage id="common.continueAndFindMatches" />
              </CustomButton>
              <CustomButton
                className="btn ghost btn--small"
                onClick={() => setIsOpen(false)}
                trackingEvent={GA_EVENTS.REQUIREMENTS.MATCHES_NUMBER_CANCEL}
              >
                <FormattedMessage id="common.cancel" />
              </CustomButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MaxCandidateSelect
