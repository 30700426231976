import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { fetchCvs } from "./utils/fetchCVs"
import { setPendingCVs } from "../redux/slices/cvSlice"

const POLLING_TIME = 15_000 // 15 seconds in ms

const useFetchCVs = ({ dispatch, enabled }) => useQuery({
  queryKey: ['fetchCVs'],
  queryFn: () => fetchCvs(dispatch),
  enabled,
  refetchInterval: POLLING_TIME,
  gcTime: POLLING_TIME,
});

/**
 * @returns {void}
 */
export const useProcessedCVsPolling = (pendingCVs) => {
  const dispatch = useDispatch()

  // Load CVs on mount
  useEffect(() => {
    fetchCvs(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pollingEnabled = pendingCVs?.length > 0;

  const { isFetching, data: cvs } = useFetchCVs({
    dispatch,
    enabled: pollingEnabled,
  });

  if (isFetching || !cvs?.length) return;

  const cvsIds = cvs.map((cv) => cv.id) ?? [];
  const pendingAlreadyProcessed = pendingCVs.filter((pendingCV) =>
    !cvIsStillPending(pendingCV, cvsIds),
  );

  if (pendingAlreadyProcessed?.length) {
    const updatedPendingCVs = pendingCVs.filter(
      (pendingCV) => !pendingAlreadyProcessed.includes(pendingCV),
    );
    dispatch(setPendingCVs(updatedPendingCVs));
  }
}

const cvIsStillPending = (pendingCV, cvsIds) =>
  !cvsIds.includes(pendingCV.id) && pendingCV.status === "IN_PROGRESS";
