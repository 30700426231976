import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import classNames from "classnames"
import { logoutAction } from "../../../redux/slices/authSlice"
import CustomButton from "../CustomButton"
import CustomLink from "../CustomLink"
import { landingPageBaseUrl } from "../../../constants"
import { GA_EVENTS } from "../../../constants/analytics"
import { ROUTES } from "../../../constants/routes"


import { FormattedMessage } from "react-intl"
import LanguageMenu from "./LanguageMenu"
import MobileNavbar from "./MobileNavbar"

const LandingPageNavbar = ({
  signUpUrl,
  showBackground = false,
  loginUrl,
  showNavbarArt = true,
  showConsole = false,
}) => {
  const [_, __, removeCookie] = useCookies(["token"]) // eslint-disable-line no-unused-vars
  const auth = useSelector((state) => state.auth)
  const [showMenu, setShowMenu] = useState(false)
  const [showLanguageMenu, setShowLanguageMenu] = useState(false)
  const [scrolled, setScrolled] = useState(
    document.scrollingElement.scrollTop > 70,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    const onScroll = () => {
      const hasScrolled = document.scrollingElement.scrollTop > 10
      setScrolled(hasScrolled)
    }
    document.addEventListener("scroll", onScroll)

    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [])

  const handleLogout = () => {
    removeCookie("token", {
      path: "/",
    })
    dispatch(logoutAction())
  }

  const menuItems = auth.isAuthenticated ? null : (
    <>
      <CustomLink trackingEvent={GA_EVENTS.MENU.GEN_AI} to={`${landingPageBaseUrl}/genai`}>
        <FormattedMessage id="navbar.platform" />
      </CustomLink>
      <CustomLink trackingEvent={GA_EVENTS.MENU.GEN_AI} to={`${landingPageBaseUrl}/#use-cases`}>
        <FormattedMessage id="navbar.useCases" />
      </CustomLink>
      <CustomLink trackingEvent={GA_EVENTS.MENU.GEN_AI} to={`${landingPageBaseUrl}/pricing`}>
        <FormattedMessage id="navbar.pricing" />
      </CustomLink>
      <CustomLink trackingEvent={GA_EVENTS.MENU.TALK_US} to={`${landingPageBaseUrl}/blog`}>
        <FormattedMessage id="navbar.blog" />
      </CustomLink>
      <CustomLink
        trackingEvent={GA_EVENTS.MENU.ABOUT_US}
        to={`${landingPageBaseUrl}/about`}
      >
        <FormattedMessage id="navbar.aboutUs" />
      </CustomLink>
    </>
  )

  const menuButtons = auth.isAuthenticated ? (
    <>
      <CustomButton
        trackingEvent={GA_EVENTS.USER_SESSION.NAV_LOGOUT}
        className="btn btn--small secondary btn--nav landing-page-navbar__desktop-button"
        onClick={handleLogout}
      >
        <FormattedMessage id="navbar.logOut" />
      </CustomButton>
      {showConsole && (
        <CustomLink
          trackingEvent={GA_EVENTS.NAV.CONSOLE}
          to={"/job-match/select-cv"}
          className="btn btn--small secondary btn--nav landing-page-navbar__desktop-button"
          onClick={handleLogout}
        >
          <FormattedMessage id="navbar.goToConsole" />
        </CustomLink>
      )}
      <CustomButton
        className="btn btn--small text btn--nav landing-page-navbar__desktop-button"
        onClick={() => setShowLanguageMenu(true)}
      >
        <FormattedMessage id="language.shortName" />
      </CustomButton>
    </>
  ) : (
    <>
      <CustomLink
        trackingEvent={GA_EVENTS.USER_REGISTRATION.NAV_GET_STARTED}
        // to={signUpUrl}
        className="secondary-link"
        to={"https://calendly.com/francois-06w/intro-call"}
      >
        <FormattedMessage id="navbar.demo" />
      </CustomLink>
      <CustomLink
        trackingEvent={GA_EVENTS.USER_REGISTRATION.NAV_GET_STARTED}
        className="primary btn btn--small btn--nav"
        // to={signUpUrl}
        to={ROUTES.FREEMIUM_SIGNUP}
      >
        <FormattedMessage id="navbar.freeTrial" />
      </CustomLink>
      <CustomLink
        trackingEvent={GA_EVENTS.USER_SESSION.NAV_SIGN_IN}
        className="btn btn--small secondary btn--nav landing-page-navbar__desktop-button"
        to={loginUrl}
      >
        <FormattedMessage id="navbar.signIn" />
      </CustomLink>
      <CustomButton
        className="btn btn--small text btn--nav landing-page-navbar__desktop-button"
        onClick={() => setShowLanguageMenu(true)}
      >
        <FormattedMessage id="language.shortName" />
      </CustomButton>
    </>
  )

  return (
    <>
      <div
        className={classNames("landing-page-navbar", {
          "landing-page-navbar--sticky": scrolled || showBackground,
        })}
      >
        <header className="landing-page-navbar__wrapper">
          <CustomLink
            trackingEvent={GA_EVENTS.NAV.LOGO}
            to={landingPageBaseUrl}
            className="logo"
          >
            <img src="/img/fribl-LOGO-spaced.png" alt="" />
          </CustomLink>
          <nav className="landing-page-navbar__desktop">{menuItems}</nav>
          <nav className="landing-page-navbar__buttons landing-page-navbar__buttons--desktop">
            {menuButtons}
          </nav>

          <nav className="landing-page-navbar__toggle">
            <div
              className="nav-toggle-btn"
              role={"button"}
              onClick={() => setShowMenu(true)}
            >
              {hamburgerSvg}
            </div>
          </nav>

          <div
            className={classNames("landing-page-navbar__backdrop", {
              "landing-page-navbar__backdrop--visible": showMenu,
            })}
            onClick={() => setShowMenu(false)}
          />

          <MobileNavbar
            showMenu={showMenu}
            closeMenu={() => setShowMenu(false)}
            menuItems={menuItems}
            isAuthenticated={auth.isAuthenticated}
            signUpUrl={signUpUrl}
            loginUrl={loginUrl}
            showConsole={showConsole}
            handleLogout={handleLogout}
          />

          <LanguageMenu
            showMenu={showLanguageMenu}
            closeMenu={() => setShowLanguageMenu(false)}
            isAuthenticated={auth.isAuthenticated}
          />
        </header>
      </div>
      {showNavbarArt && (
        <>
          <div className="landing-page__green-circle" />
        </>
      )}
    </>
  )
}

const hamburgerSvg = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="2" fill="white" />
    <rect y="6" width="16" height="2" fill="white" />
    <rect y="12" width="16" height="2" fill="white" />
  </svg>
)

export default LandingPageNavbar
