import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
  cvs: null,
  pendingCVs: [],
}

const cvSlice = createSlice({
  name: "cv",
  initialState: INITIAL_STATE,
  reducers: {
    reset: (state) => {
      state = INITIAL_STATE
    },
    setCvs: (state, action) => {
      state.cvs = action.payload
    },
    setPendingCVs: (state, action) => {
      state.pendingCVs = action.payload
    },
    addPendingCVs: (state, action) => {
      state.pendingCVs = [
        ...state.pendingCVs,
        ...action.payload.map(({ cvId, file_name }) => ({
          id: cvId,
          information: {
            file_name: file_name,
          },
          status: "IN_PROGRESS",
        })),
      ]
    },
  },
})

export const { reset, setCvs, addPendingCVs, setPendingCVs } = cvSlice.actions

export default cvSlice.reducer
