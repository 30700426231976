import { toast } from "react-toastify"
import { getCvs } from "../cv"
import { setCvs } from "../../redux/slices/cvSlice"
import intl from "../../i18n/intl"

export const fetchCvs = async (dispatch, callback = () => {}) => {
  return getCvs()
    .then((cvs) => {
      callback(cvs)

      dispatch(setCvs(cvs))

      return cvs
    })
    .catch(() => {
      const { formatMessage } = intl()
      toast.error(formatMessage({ id: "toast.fetchCVsFail" }))
    })
}
