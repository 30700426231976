import axios from "../api"
import { getToken } from "../util/util"

axios.defaults.timeout = 1000000

/**
 * @param {*} body
 * @returns {Promise<{message: string; cvId: string; file_name: string}>}
 */
export const uploadCV = (body) =>
  axios
    .post("/cv/upload", body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
        "Cache-Control": "no-cache",
      },
    })
    .then((response) => response.data)

export const getCV = (key) =>
  axios.get(`/cv/get?key=${key}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Cache-Control": "no-cache",
    },
  })

export const deleteCvs = (keys) =>
  axios.post(
    `/cv/delete`,
    { keys },
    { headers: {
      Authorization: `Bearer ${getToken()}`,
      "Cache-Control": "no-cache",
    }},
  )

export const getCvs = () =>
  axios
    .post(`/cv/getAll`, null, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Cache-Control": "no-cache",
      }
    })
    .then((res) => {
      return res.data.data.reverse()
    })

export const importCvs = (body) =>
  axios
    .post(`/cv/import-cv`, body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Cache-Control": "no-cache",
      }
    })
    .then((res) => res.data)
