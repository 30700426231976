import api from "../api"
import { getToken } from "../util/util"

export const login = async ({ email, password }) =>
  api.post("/users/login", { email, password }).then((res) => res.data)

export const signup = async (body) =>
  api.post("/users/signup", body).then((res) => res.data)

export const freeTrialSignup = async (body) =>
  api.post("/users/freemium-signup", body).then((res) => res.data)

export const verifyEmail = async (body) =>
  api.post("/users/signup/verify-email", body).then((res) => res.data)

export const resendVerificationEmail = async (body) =>
  api.post("/users/signup/resend-email", body).then((res) => res.data)

export const addCompanyInfo = async (body) =>
  api.post("/users/signup/company-info", body).then((res) => res.data)

export const addCompanyInfoForTrialUsers = async (body) =>
  api
    .post("/users/signup/trial/company-info", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const addPayment = async (body) =>
  api
    .post("/users/signup/payment", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const backToChoosePricing = async () =>
  api
    .post("/users/signup/choose-another-product", null, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const isEmailFree = async (body) =>
  api.post("/users/is-email-free", body).then((res) => res.data)

export const isPhoneNumberFree = async (body) =>
  api
    .post("/users/is-phone-free", body, {
      headers: {
        Authorization: `Bearer ${getToken() ?? ""}`,
      },
    })
    .then((res) => res.data)

export const me = async ({ token }) =>
  api
    .get("/users/me", { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data)

export const completeInvitation = async (body) =>
  api.post("/users/complete-invitation", body).then((res) => res.data)

export const updatePersonalDetails = async (body) =>
  api
    .post("/users/me/update", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const forgotPassword = async (body) =>
  api.post("/users/send-password-reset-email", body).then((res) => res.data)

export const resetPassword = async (body) =>
  api.post("/users/reset-password", body).then((res) => res.data)
