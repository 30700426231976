// TODO: Add routes to be used in the app in this file for centralized management
export const ROUTES = {
  ROOT: "/",

  // Sign up
  CREATE_ACCOUNT: "/create-account",
  FREEMIUM_SIGNUP: "/freemium-signup",

  // Sign in
  FORGOT_PASSWORD: "/forgot-password",

  // Account Creation
  COMPANY_INFO: "/company-info",
  CHOOSE_PRICING: "/choose-pricing",
  PENDING_PAYMENT: "/pending-payment",

  // Main
  CANDIDATE_CVS: "/job-match/select-cv"
};
