import React, { useEffect, useState } from "react"
import { Link, useParams, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { ErrorBoundary } from "react-error-boundary"

import { CV, Spinner } from "../../common"
import { getCV } from "../../../services/cv"
import { setSelectedCVs } from "../../../redux/slices/jobSlice"
import { Resume } from "./components"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

const BackButton = ({ backButton }) => backButton && (
  <Link className="ResumePage__back-btn" to={backButton}>
    <FormattedMessage id="common.backToCVs" />
  </Link>
);

const BackButtonSection = ({ backButton, children }) => (
  <div className="ResumePage">
    <BackButton backButton={backButton} />
    {children}
  </div>
);

const SelectAResume = (backButton) => (
  <BackButtonSection backButton={backButton} >
    <p className="ResumePage__no-data">
      <FormattedMessage id="common.pleaseSelectAResume" />
    </p>
  </BackButtonSection>
);

const ResumeNotFound = (backButton) => (
  <BackButtonSection backButton={backButton}>
    <p className="ResumePage__no-data">
      <FormattedMessage id="common.resumeNotFound" />
    </p>
  </BackButtonSection>
);

const LoadingData = (backButton) => (
  <BackButtonSection backButton={backButton}>
    <div className="ResumePage__loading">
      <Spinner />
    </div>
  </BackButtonSection>
);

const AnalyzingData = (backButton) => (
  <BackButtonSection backButton={backButton}>
    <p className="ResumePage__no-data">
      <FormattedMessage id="common.cvBeingAnalyzed" />
    </p>
  </BackButtonSection>
);

const ParsingError = (backButton) => (
  <BackButtonSection backButton={backButton}>
    <p className="ResumePage__no-data">
      <FormattedMessage id="common.unableToParseCv" />
    </p>
    <Link className="ResumePage__go-back" to={-1}>
      <FormattedMessage id="message.goBack" />
    </Link>
  </BackButtonSection>
);

const ErrorFallback = () => (
  <div className="error-msg">
    <p className="h3">
      <FormattedMessage id="common.somethingWentWrong" />
    </p>
    <Link className="btn primary btn--small" to="/">
      <FormattedMessage id="common.goBackToHomepage" />
    </Link>
  </div>
);

function ResumePage({ showCheckbox, backButton: backBtn }) {
  const { key, jobId } = useParams()
  const { state } = useLocation();

  const [cvData, setCvData] = useState(null || state?.cv)
  const [noData, setNoData] = useState(false)

  const [activeTab, setActiveTab] = useState("profile")

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  let backButton = backBtn
  if (jobId) {
    backButton = backButton.replace(":jobId", jobId)
  }

  const selectedCVs = useSelector((state) => state.jobs.selectedCVs)
  const isSelected = selectedCVs.includes(key)

  const handleCheckboxChange = () => {
    const newSetOfSelectedCVs = [...selectedCVs]
    if (isSelected) {
      // Remove
      const index = newSetOfSelectedCVs.indexOf(key)
      newSetOfSelectedCVs.splice(index, 1)
    } else {
      // Add
      newSetOfSelectedCVs.push(key)
    }
    dispatch(setSelectedCVs(newSetOfSelectedCVs))
  }

  useEffect(() => {
    if (key && !cvData) {
      setCvData(null)
      setNoData(false)
      getCV(key).then((res) => {
        if (res.data.error) {
          toast.error(formatMessage({ id: "toast.unableToFetchCV" }), {
            toastId: "error",
          })
          setNoData(true);
        }
        setCvData(res.data)
      })
    } else {
      if (!cvData) setNoData(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, cvData])

  if (!key) {
    return <SelectAResume backButton={backButton} />;
  }

  if (noData) {
    return <ResumeNotFound backButton={backButton} />;
  }

  if (!cvData) {
    return <LoadingData backButton={backButton} />;
  }

  // TODO: To be implemented with the new GET response status
  // if (cvData.status !== "COMPLETED") {
  //   return <AnalyzingData backButton={backButton} />;
  // }

  const { experience, education, skill_descriptions } = cvData

  if (!experience || !skill_descriptions || !education) {
    return <ParsingError backButton={backButton} />;
  }

  return (
    <div className="ResumePage">
      <div className="ResumePage__nav">
        <BackButton backButton={backButton} />

        <div className="ResumePage__nav-btns">
          <button
            className={classNames("ResumePage__nav-btn", {
              selected: activeTab === "profile",
            })}
            onClick={() => setActiveTab("profile")}
          >
            <FormattedMessage id="common.profile" />
          </button>
          <button
            className={classNames("ResumePage__nav-btn", {
              selected: activeTab === "cv",
            })}
            onClick={() => setActiveTab("cv")}
          >
            <FormattedMessage id="common.cv" />
          </button>
        </div>
      </div>
      <ErrorBoundary
        fallback={<ErrorFallback />}
      >
        {activeTab === "profile" && (
          <Resume
            cv={cvData}
            showCheckbox={showCheckbox}
            handleCheckboxChange={handleCheckboxChange}
            isSelected={isSelected}
          />
        )}
        {activeTab === "cv" && <CV url={cvData.cv_url} />}
      </ErrorBoundary>
    </div>
  )
}

export default ResumePage
