import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import classNames from "classnames"

// Redux
import { loginAction } from "../../../redux/slices/authSlice"
import { login } from "../../../services/auth"
import { reset as resetCVsSlice } from "../../../redux/slices/cvSlice"
import { resetCreditSlice } from "../../../redux/slices/creditSlice"
import { resetIntegrationSlice } from "../../../redux/slices/integrationSlice"
import { resetJobSlice } from "../../../redux/slices/jobSlice"

// Components
import { SignupInput, CustomButton, CustomLink } from "../../common"

// Constants
import { GA_EVENTS } from "../../../constants/analytics"
import { ROUTES } from "../../../constants/routes"
import { ONBOARDING_STAGE } from "../../../constants"

function LoginPage() {
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie, __] = useCookies(["token"])
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    getValues,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  })

  watch(["email", "password"])

  const values = getValues()

  const onSubmit = (data) => {
    if (loading) {
      return
    }
    setLoading(true)
    login(data)
      .then((data) => {
        dispatch(resetCVsSlice())
        dispatch(resetCreditSlice())
        dispatch(resetIntegrationSlice())
        dispatch(resetJobSlice())

        setLoading(false)
        if (data.data.onboardingStage === ONBOARDING_STAGE.ACCOUNT_CREATION) {
          navigate(ROUTES.COMPANY_INFO)
        }

        if ([ONBOARDING_STAGE.COMPLETE, ONBOARDING_STAGE.TRIAL].includes(data.data.onboardingStage)) {
          navigate(ROUTES.CANDIDATE_CVS)
        }

        if (data.data.onboardingStage === ONBOARDING_STAGE.CHOOSE_PRICE) {
          navigate(ROUTES.CHOOSE_PRICING)
        }

        if (data.data.onboardingStage === ONBOARDING_STAGE.PENDING_PAYMENT) {
          navigate(ROUTES.PENDING_PAYMENT)
        }

        setCookie("token", data.token, {
          path: ROUTES.ROOT,
          expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
        })
        dispatch(loginAction(data))
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || "An error occurred. Please try again."
        toast.error(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <h1 className="login-page__title ">
          <FormattedMessage id="login.greeting" />, <br />
          <span>
            <FormattedMessage id="login.welcomeBack" />
          </span>
        </h1>
        <p className="login-page__subtitle">
          <FormattedMessage id="login.noAccount" /> &nbsp;
          <CustomLink
            trackingEvent={GA_EVENTS.USER_REGISTRATION.CREATE}
            // to={ROUTES.CREATE_ACCOUNT}
            to={ROUTES.FREEMIUM_SIGNUP}
          >
            <FormattedMessage id="login.getStarted" />
          </CustomLink>
        </p>
        <form className="login-page__content" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-page__inputs">
            <SignupInput
              register={register("email", {
                required: formatMessage({ id: "common.emailRequired" }),
              })}
              placeholder={formatMessage({ id: "common.email" })}
              error={errors.email?.message}
              touched={touchedFields.email}
              value={values.email}
              type="email"
              label={formatMessage({ id: "common.email" })}
            />
            <SignupInput
              register={register("password", {
                required: formatMessage({ id: "common.passwordRequired" }),
              })}
              placeholder={formatMessage({ id: "common.password" })}
              error={errors.password?.message}
              touched={touchedFields.password}
              value={values.password}
              type="password"
            />
            <CustomLink
              trackingEvent={GA_EVENTS.USER_SESSION.LOGIN_FORGOT}
              className={"login-page__link"}
              to={ROUTES.FORGOT_PASSWORD}
            >
              <FormattedMessage id="login.forgotten" />
            </CustomLink>
          </div>

          <CustomButton
            trackingEvent={GA_EVENTS.USER_SESSION.SIGN_IN}
            className={classNames("btn btn--small primary", {
              disabled: !isValid || loading,
            })}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="login.signIn" />
          </CustomButton>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
