import axios from "../api"
import { getToken } from "../util/util"

export const postJobRequirements = (body) =>
  axios.post("/jobs/requirements", body, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Cache-Control": "no-cache",
    },
  })

/**
 * @param {*} body
 * @returns {{title: string; description: string}}
 */
export const parseJobRequirementsFile = (body) =>
  axios
    .post("/jobs/requirements/file", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Cache-Control": "no-cache",
      },
    })
    .then((res) => res.data)

export const getJobDetail = ({ ad_id }) =>
  axios.get("/jobs/detail", {
    params: {
      ad_id,
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Cache-Control": "no-cache",
    },
  })

export const selectCandidates = (body) =>
  axios.post("/jobs/select-candidates", body, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Cache-Control": "no-cache",
    }
  })

export const getMatchedRequirements = ({ ad_id }) =>
  axios.get("/jobs/requirements_match", {
    params: {
      ad_id,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      "Cache-Control": "no-cache",
    },
  })

export const postMatchRequirements = (body) =>
  axios.post("/jobs/requirements_match", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      "Cache-Control": "no-cache",
    },
  })

export const getJobMatches = () =>
  axios
    .get("/jobs/list", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Cache-Control": "no-cache",
      },
    })
    .then((res) =>
      res.data
        .map((job) => ({
          ...job,
          candidates: job.candidates?.map(({ M }) => ({ ...M })),
        }))
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
    )
