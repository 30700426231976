//# General Guidelines
// (to differentiate this events from the ones automatically generated in GA4 via Tags)
//
//- Keep categories UPPERCASE
//- Actions: {page or section} > {action}
//    both in snake_case

export const GA_EVENTS = {
  GENERAL: {
    BUTTON_PRESSED: {
      category: "GENERAL",
      action: "button > pressed"
    },
    LINK_CLICKED: {
      category: "GENERAL",
      action: "link > clicked"
    },
    NAV_LINK_CLICKED: {
      category: "GENERAL",
      action: "nav_link > clicked"
    },
    SIDE_BAR_CLICKED: {
      category: "GENERAL",
      action: "side_bar > clicked"
    },
    SUBSCRIPTION_SELECTED: {
      category: "GENERAL",
      action: "subscription > clicked"
    }
  },

  CHOOSE_PRICING: {
    TAB_SELECTED: {
      category: "CHOOSE_PRICING",
      action: "choose_pricing > hero_tab_selected"
    },

    PayAsYouGo: {
      category: "CHOOSE_PRICING",
      action: "choose_pricing > hero_pay_as_you_go"
    },
    Subscription: {
      category: "CHOOSE_PRICING",
      action: "choose_pricing > hero_subscription"
    },
    Enterprise: {
      category: "CHOOSE_PRICING",
      action: "choose_pricing > hero_enterprise"
    },

    BILLING_SELECTED: {
      category: "CHOOSE_PRICING",
      action: "choose_pricing > billing_item"
    },
    CONTACT_SALES: {
      category: "CHOOSE_PRICING",
      action: "choose_pricing > enterprise_contact_sales"
    },
    NEED_MORE_SELECTED: {
      category: "CHOOSE_PRICING",
      action: "choose_pricing > need_more_upsell"
    },
  },

  PAYMENT: {
    BACK: {
      category: "PAYMENT",
      action: "payment > back"
    },
    SUBMIT: {
      category: "PAYMENT",
      action: "payment > submit"
    },
    CONFIRMATION_NEXT: {
      category: "PAYMENT",
      action: "payment_confirmation > next"
    }
  },

  MENU: {
    ABOUT_US: {
      category: "MENU",
      action: "menu > about_us"
    },
    GEN_AI: {
      category: "MENU",
      action: "menu > gen_ai"
    },
    TALK_US: {
      category: "MENU",
      action: "menu > talk_us"
    }
  },

  NAV: {
    CONSOLE: {
      category: "NAV",
      action: "nav > console"
    },
    LOGO: {
      category: "NAV",
      action: "nav > logo"
    },
    MOBILE_LOGO: {
      category: "NAV",
      action: "nav > mobile_logo"
    },

    SELECT_CV: {
      category: "NAV",
      action: "nav > select_cv"
    },
    JOB_MATCHES: {
      category: "NAV",
      action: "nav > job_matches"
    },
    BUY_MORE: {
      category: "NAV",
      action: "nav > buy_more"
    },
    NEW_JOB_MATCH: {
      category: "NAV",
      action: "nav > new_job_match"
    }
  },

  SIDE_BAR: {
    BACK: {
      category: "SIDE_BAR",
      action: "side_bar > back"
    },
    TEAM_MEMBERS: {
      category: "SIDE_BAR",
      action: "side_bar > team_members"
    },
    SETTINGS: {
      category: "SIDE_BAR",
      action: "side_bar > settings"
    },
    CONNECT_TO_ATS: {
      category: "SIDE_BAR",
      action: "side_bar > connect_to_ats"
    },
    PLAN_AND_BILLING: {
      category: "SIDE_BAR",
      action: "side_bar > plan_and_billing"
    },
    LOGOUT: {
      category: "SIDE_BAR",
      action: "side_bar > logout"
    },
  },

  JOB_MATCHES: {
    ACTION_MATCH_SCORE: {
      category: "JOB_MATCHES",
      action: "candidate_actions > match_score"
    },
    ACTION_PROFILE_TAB: {
      category: "JOB_MATCHES",
      action: "candidate_actions > profile"
    },
    ACTION_CV: {
      category: "JOB_MATCHES",
      action: "candidate_actions > cv"
    },
    BACK_AND_CONTINUE: {
      category: "JOB_MATCHES",
      action: "still_working > go_back_and_continue"
    },
    SIDE_BAR_CANDIDATE_CVS: {
      category: "JOB_MATCHES",
      action: "side_bar > candidate_cvs"
    },
    SIDE_BAR_REQUIREMENTS: {
      category: "JOB_MATCHES",
      action: "side_bar > job_requirements"
    },
  },

  JOB_SPEC: {
    CREATE_JOB_MATCH: {
      category: "JOB_SPEC",
      action: "job_spec > create_job_match"
    },
    IMPORT_FROM_ATS: {
      category: "JOB_SPEC",
      action: "job_spec > import_from_ats"
    },
    GET_MORE_UPSELL: {
      category: "JOB_SPEC",
      action: "job_spec > get_more_matches_upsell"
    },
    PASTE_TEXT: {
      category: "JOB_SPEC",
      action: "job_spec > paste_text"
    },
    UPLOAD: {
      category: "JOB_SPEC",
      action: "job_spec > upload_from_pc"
    }
  },

  REQUIREMENTS: {
    ADD_MORE_CREDITS: {
      category: "REQUIREMENTS",
      action: "requirements > add_more_credits"
    },
    CANCEL_AND_BACK: {
      category: "REQUIREMENTS",
      action: "requirements > cancel_and_go_back"
    },
    FIND_TOP_MATCHING: {
      category: "REQUIREMENTS",
      action: "requirements > find_top_matching"
    },
    MATCHES_NUMBER_CONFIRM: {
      category: "REQUIREMENTS",
      action: "matches_number_modal > continue"
    },
    MATCHES_NUMBER_CANCEL: {
      category: "REQUIREMENTS",
      action: "matches_number_modal > cancel"
    },
  },

  SELECT_CV: {
    ADD_JOB_DESC: {
      category: "SELECT_CV",
      action: "select_cv > add_job_description"
    },
    IMPORT_GREENHOUSE: {
      category: "SELECT_CV",
      action: "select_cv > import_from_greenhouse"
    },
    MODAL_CANCEL: {
      category: "SELECT_CV",
      action: "select_cv_modal > cancel_delete"
    },
    MODAL_DELETE: {
      category: "SELECT_CV",
      action: "select_cv_modal > delete_selected_cvs"
    },
    TOGGLE_DROPDOWN: {
      category: "SELECT_CV",
      action: "select_cv > toggle_dropdown"
    },
    UPLOAD: {
      category: "SELECT_CV",
      action: "select_cv > upload_cv"
    },


  },

  USER_REGISTRATION: {
    // Creation
    CREATE: {
      category: "USER_REGISTRATION",
      action: "login > create"
    },
    CREATE_CONTINUE: {
      category: "USER_REGISTRATION",
      action: "create_account > continue"
    },
    FREE_TRIAL_CONTINUE: {
      category: "USER_REGISTRATION",
      action: "create_free_trial > continue"
    },
    FREEMIUM_CONFIRM: {
      category: "USER_REGISTRATION",
      action: "create_freemium_account > confirm_next"
    },
    NAV_GET_STARTED: {
      category: "USER_REGISTRATION",
      action: "nav > get_started"
    },

    // Email verification
    EMAIL_LINK_EXPIRED: {
      category: "USER_REGISTRATION",
      action: "email_verification > expired_send_new_link"
    },
    EMAIL_SENT_GO_BACK: {
      category: "USER_REGISTRATION",
      action: "email_verification > link_sent_go_back"
    },
    EMAIL_VERIFIED: {
      category: "USER_REGISTRATION",
      action: "email_verification > verified_continue"
    },
    EMAIL_VERIFICATION_DEFAULT: {
      category: "USER_REGISTRATION",
      action: "email_verification > default_continue"
    },
  },

  USER_SESSION: {
    NAV_SIGN_IN: {
      category: "USER_SESSION",
      action: "nav > sign_in"
    },
    NAV_LOGOUT: {
      category: "USER_SESSION",
      action: "nav > logout"
    },

    BACK_TO_LOGIN: {
      category: "USER_SESSION",
      action: "forgot_password > back_to_login"
    },
    FORGOT_PASSWORD: {
      category: "USER_SESSION",
      action: "forgot_password > confirm"
    },
    LOGIN_FORGOT: {
      category: "USER_SESSION",
      action: "login > forgotten"
    },
    SIGN_IN: {
      category: "USER_SESSION",
      action: "login > sign_in"
    }
  }
}