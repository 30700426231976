import React from "react"
import { Checkbox } from "../../../common"
import { FormattedMessage, useIntl } from "react-intl"

import { capitalizeString } from "../../../../util/format"

const Resume = ({
  cv,
  showCheckbox,
  handleCheckboxChange,
  isSelected,
}) => {
  const { formatMessage } = useIntl()
  const {
    skill_descriptions,
    experience,
    education,
    skills,
    information: { first_name, address, last_name, website, phone, email },
  } = cv
  const hardSkills = skills?.filter((skill) => skill.type === "hard-skill")
  const softSkills = skills?.filter((skill) => skill.type === "soft-skill")

  const profileSection = (
    <div className="section-card topbar flex-col">
      <h1>{`${first_name} ${last_name}`}</h1>

      {showCheckbox && (
        <button
          className="ResumePage__checkbox"
          onClick={handleCheckboxChange}
        >
          {isSelected
            ? formatMessage({ id: "common.selectedForJobMatch" })
            : formatMessage({ id: "common.selectForJobMatch" })}
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            variant="tertiary"
            onChange={handleCheckboxChange}
            value={isSelected}
          />
        </button>
      )}

      {phone
        ?.filter((num) => num)
        ?.map((number) => (
          <p key={number}>
            <strong>
              <FormattedMessage id="common.phoneNumber" />{" "}
            </strong>{" "}
            {number}
          </p>
        ))}

      {email
        ?.filter((num) => num)
        ?.map((email, index) => (
          <p key={email}>
            <strong>
              <FormattedMessage id="common.email" />{" "}
            </strong>{" "}
            {email}
          </p>
        ))}

      <p>
        <strong>
          <FormattedMessage id="common.website" />{" "}
        </strong>
        <span className="long-text">{website}</span>
      </p>

      {cv.dateOfBirth && (
        <p>
          <strong>
            <FormattedMessage id="common.dateOfBirth" />{" "}
          </strong>{" "}
          {cv.dateOfBirth}
        </p>
      )}

      <p>
        <strong>
          <FormattedMessage id="common.address" />{" "}
        </strong>
        {address.street && `${address.street}, `}
        {address.city && `${address.city}, `}
        {address.zip && `${address.zip}, `}
        {address.country && `${address.country}`}
      </p>

      <h4>{cv.summary}</h4>
    </div>
  );

  const highlightsSection = (
    <div className="resume__highlights-wrapper">
      <div className="resume__highlights-item">
        <p className="resume__highlights-title">
          <FormattedMessage id="common.highlights" />
        </p>
      </div>

      {skill_descriptions.map((value) => (
        <div className="resume__highlights-item" key={`Highlight - ${value}`}>
          <p className="resume__highlights-info">{value}</p>
        </div>
      ))}
    </div>
  );

  const experienceSection = (
    <div className="work-experience flex-col">
      <div className="skill">
        <h3 className="strong">
          <FormattedMessage id="common.experience" />
        </h3>
      </div>

      <div className="roles">
        {experience?.map((experience, index) => (
          <React.Fragment key={`${experience.title}-${experience.start_date}`}>
            <div className="role flex-col skill">
              <h4 className="strong">{experience.activity_sector}</h4>
              <h4>{experience.title}</h4>
              {experience.description && (
                <p>{experience.description}</p>
              )}
              <div className="dates">
                {`${experience.start_date} ${formatMessage({
                  id: "common.to",
                })} ${experience.end_date ?? "N/A"}`}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )

  const educationSection = (
    <div className="education flex-col">
      <div className="skill">
        <h3 className="strong">
          <FormattedMessage id="common.education" />
        </h3>
      </div>

      <div className="schools">
        {education?.map((education) => (
          <React.Fragment key={`${education.degree}-${education.institution_name}-${education.start_date}`}>
            <div className="role flex-col skill">
              <h4 className="strong">{education.degree}</h4>
              <h4>{education.institution_name}</h4>
              <h5>{education.major}</h5>
              <div className="dates">
                {`${
                  education.totalYears
                    ? `${education.totalYears} ${formatMessage({
                        id: "common.from",
                      })}`
                    : ""
                } ${
                  education.start_date && education.end_date
                    ? `${education.start_date} ${formatMessage({
                        id: "common.to",
                      })} ${education.end_date ?? "N/A"}`
                    : ""
                }`}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  const certificationsSection = cv.certifications?.[0]?.name && (
    <div className="education flex-col">
      <div className="skill">
        <h3>
          <FormattedMessage id="common.certifications" />
        </h3>
      </div>
      <div className="schools">
        {cv.certifications?.map((certification, index) => (
          <p className="role flex-col skill" key={`Cert - ${index}`}>
            {certification.name}
          </p>
        ))}
      </div>
    </div>
  )

  const hardSkillsSection = (
    <div className="skills flex-col">
      <div className="skill">
        <h3 className="strong">
          <FormattedMessage id="common.hardSkills" />
        </h3>
      </div>

      <div className="schools">
        {hardSkills?.map((hardSkill) => (
          <div className="role flex-col skill" key={hardSkill.id}>
            <h4>{capitalizeString(hardSkill.skill)}</h4>
            <h5>{hardSkill.explanation}</h5>
          </div>
        ))}
      </div>
    </div>
  )

  const softSkillsSection = (
    <div className="skills flex-col">
      <div className="skill">
        <h3 className="strong">
          <FormattedMessage id="common.softSkills" />
        </h3>
      </div>

      <div className="schools">
        {softSkills?.map((softSkill) => (
          <div className="role flex-col skill" key={softSkill.id}>
            <h4>{capitalizeString(softSkill.skill)}</h4>
            <h5>{softSkill.explanation}</h5>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="ResumePage__content">
      <div className="flex-col">
        {profileSection}
        {highlightsSection}
      </div>

      <div>
        {experienceSection}
        {educationSection}
        {certificationsSection}
        {hardSkillsSection}
        {softSkillsSection}
      </div>
    </div>
  )
}

export default Resume
