import React, { useCallback, useEffect, useState } from "react"
import { Controller, set, useForm } from "react-hook-form"
import debounce from "lodash.debounce"
import { useCookies } from "react-cookie"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate, Navigate } from "react-router-dom"

import {
  PhoneNumberInput,
  Select,
  SignupInput,
  StaffNumberSelector,
} from "../../common"
import { BillingOptions } from "./components"
import {
  addCompanyInfo,
  addCompanyInfoForTrialUsers,
  isPhoneNumberFree,
} from "./../../../services/auth"
import { loginAction } from "../../../redux/slices/authSlice"
import { getToken } from "../../../util/util"
import { COUNTRIES } from "../../../constants"
import { FormattedMessage, useIntl } from "react-intl"

const industryOptions = [
  { value: "Finance", label: "common.finance" },
  { value: "HR Recruiters", label: "common.hrRecruiters" },
  { value: "IT/SaaS", label: "common.itSaaS" },
  { value: "Retail", label: "common.retail" },
  { value: "Services", label: "common.services" },
  { value: "Telecom", label: "common.telecom" },
  { value: "Transport", label: "common.transport" },
  { value: "Utilities", label: "common.utilities" },
  { value: "Others", label: "common.others" },
]

const CompanyInfoPage = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie] = useCookies(["token"])

  const signupForm = useSelector((state) => state.signup)
  const auth = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
  const { formatMessage } = useIntl()

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    // trigger,
    setError,
    clearErrors,
    watch,
    setFocus,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      company: signupForm.company ?? "",
      position: signupForm.position ?? "",
      countryCode: "",
      phoneNumber: signupForm.phoneNumber ?? "",
      type: signupForm.type ?? "Starter",
      industry: "",
      isTrial: false,
      newHires: signupForm.newHires ?? "",
    },
  })

  const values = getValues()

  watch([
    "company",
    "position",
    "phoneNumber",
    "type",
    "newHires",
    "countryCode",
    "industry",
  ])

  useEffect(() => {
    const isFreemium = auth.onboardingStage === "Trialing"
    if (isFreemium) {
      // Set preset values
      setValue("company", auth.team.name, { shouldTouch: true })
      setValue("phoneNumber", auth.user.phoneNumber, { shouldTouch: true })
      const country = COUNTRIES.find(
        (country) =>
          country.mobileCode === auth.user.countryCode &&
          country.code === auth.user.country,
      )
      if (country) {
        setValue(
          "countryCode",
          {
            label: country.mobileCode,
            value: country.code,
          },
          { shouldTouch: true },
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Debounced useCallback function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCallback = useCallback(
    debounce((values) => {
      setLoading(true);
      isPhoneNumberFree({
        phoneNumber: values.phoneNumber,
        countryCode: values.countryCode,
      })
        .then(() => {
          setIsValidPhoneNumber(true);
          clearErrors();
        })
        .catch(() => {
          setError("phoneNumber", {
            message: formatMessage({
              id: "common.phoneNumberAlreadyRegistered",
            }),
          })
          setIsValidPhoneNumber(false)
        })
        .finally(() => setLoading(false));
    }, 1000), // Delay of the validation after typing
    [],
  )

  useEffect(() => {
    setIsValidPhoneNumber(false)
    if (!values.phoneNumber || !values.countryCode?.label) return
    debouncedCallback({
      phoneNumber: values.phoneNumber,
      countryCode: values.countryCode?.label,
    })
  }, [values.phoneNumber, values.countryCode?.label, debouncedCallback])

  const dispatch = useDispatch()

  const onFormSubmit = (values) => {
    if (!isValidPhoneNumber) {
      return
    }
    // Validate Phone Number
    if (!values.countryCode?.value) {
      return setError("countryCode", {
        message: formatMessage({
          id: "common.countryCodeRequired",
        }),
      })
    }
    setLoading(true)
    isPhoneNumberFree({
      phoneNumber: values.phoneNumber,
      countryCode: values.countryCode?.label,
    })
      .then(async () => {
        try {
          let data
          const isFreemium = auth.onboardingStage === "Trialing"
          if (isFreemium) {
            data = await addCompanyInfoForTrialUsers({
              company: values.company,
              position: values.position,
              phoneNumber: values.phoneNumber,
              organizationType: values.type,
              country: values.countryCode.value,
              countryCode: values.countryCode.label,
              newHires: values.newHires,
              industry: values.industry?.value || null,
            })
          } else {
            data = await addCompanyInfo({
              company: values.company,
              position: values.position,
              phoneNumber: values.phoneNumber,
              organizationType: values.type,
              country: values.countryCode.value,
              countryCode: values.countryCode.label,
              token: getToken(),
              newHires: values.newHires,
              industry: values.industry?.value || null,
            })
          }

          setCookie("token", data.token, {
            path: "/",
            expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          })
          dispatch(loginAction(data))
          navigate("/choose-pricing")
        } catch (err) {
          toast.error(
            err?.response?.data?.message ||
              formatMessage({
                id: "toast.somethingWentWrong",
              }),
          )
        }
      })
      .catch(() => {
        setError("phoneNumber", {
          message: formatMessage({
            id: "common.phoneNumberAlreadyRegistered",
          }),
        })
        setFocus("phoneNumber")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!["AccountCreation", "Trialing"].includes(auth.onboardingStage)) {
    return <Navigate to={"/login"} />
  }

  const showFurtherValues =
    !!values.company &&
    !!values.position &&
    !!values.phoneNumber &&
    !!values.countryCode;

  const newHires = Number(values.newHires)

  const choosingBillingOption = showFurtherValues && values.type && newHires > 0

  const idealBillingOption =
    newHires > 100
      ? "Enterprise"
      : newHires > 36
      ? "Subscription"
      : "PayAsYouGo"

  return (
    <>
      <div className="create-account">
        <div className="create-account__wrapper">
          <h1 className="create-account__title mb-15">
            <FormattedMessage id="message.findBestFitPlan" />
          </h1>

          <div className="create-account__content">
            <div className="create-account__inputs">
              <SignupInput
                register={{
                  ...register("company", {
                    required: "Company name is required",
                    pattern: {
                      value: /^[a-zA-Z0-9]+(?:[ -][a-zA-Z0-9]+)*$/,
                      message: formatMessage({
                        id: "common.somethingDoesntLookRight",
                      }),
                    },
                  }),
                }}
                placeholder={formatMessage({
                  id: "common.whatsYourCompanyName",
                })}
                error={errors.company?.message}
                touched={touchedFields.company}
                value={values.company}
                label={formatMessage({
                  id: "common.company",
                })}
              />

              <PhoneNumberInput
                errors={errors}
                isValid={isValidPhoneNumber}
                isValidating={loading}
                touchedFields={touchedFields}
                phoneNumberRegister={register("phoneNumber", {
                  required: formatMessage({
                    id: "common.phoneNumberRequired",
                  }),
                  // Add validation to include only numbers
                  pattern: {
                    value: /^[0-9\s-]+$/,
                    message: formatMessage({
                      id: "common.somethingDoesntLookRight",
                    }),
                  },
                })}
                control={control}
                values={values}
                label={formatMessage({
                  id: loading ? "common.validatingPhoneNumber" : "common.phoneNumber",
                })}
              />

              <Controller
                control={control}
                name="industry"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    name={"industry"}
                    placeholder={formatMessage({
                      id: "common.industryOrSector",
                    })}
                    options={industryOptions}
                    getOptionLabel={({ label }) => formatMessage({ id: label })}
                    label={formatMessage({
                      id: "common.industry",
                    })}
                  />
                )}
              />

              <SignupInput
                register={register("position", {
                  required: formatMessage({
                    id: "common.roleRequired",
                  }),
                  pattern: {
                    value: /^[a-zA-Z -]+$/,
                    message: formatMessage({
                      id: "common.somethingDoesntLookRight",
                    }),
                  },
                })}
                placeholder={formatMessage({
                  id: "common.whatIsYourRoleThere",
                })}
                error={errors.position?.message}
                touched={touchedFields.position}
                value={values.position}
                label={formatMessage({
                  id: "common.role",
                })}
              />

              {showFurtherValues && (
                <>
                  <p className="create-account__helper mt-3">
                    <FormattedMessage id="message.finalTwoQuestionsForMinCostPerHire" />
                  </p>
                  <div>
                    <p className="create-account__label">
                      <FormattedMessage id="common.numberOfStaff" />
                    </p>
                    <Controller
                      control={control}
                      name="type"
                      render={({ field: { onChange, value } }) => (
                        <StaffNumberSelector
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>

                  <div className="mt-3">
                    <p className="create-account__label">
                      <FormattedMessage id="message.howManyPlanToHireNextYear" />
                    </p>
                    <SignupInput
                      register={{
                        ...register("newHires", {
                          required: formatMessage({
                            id: "common.newHireCountRequired",
                          }),
                          valueAsNumber: true,
                        }),
                      }}
                      type="number"
                      min={1}
                      step={1}
                      placeholder={formatMessage({
                        id: "common.wellProbablyHireAround",
                      })}
                      error={errors.newHires?.message}
                      touched={touchedFields.newHires}
                      value={values.newHires}
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </div>

                  {choosingBillingOption && (
                    <>
                      <p className="create-account__title mt-20">
                        <FormattedMessage id="common.chooseBillingOption" />
                      </p>

                      <p className="create-account__helper">
                        <FormattedMessage id="common.changeYourMindLater" />
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {choosingBillingOption && (
          <BillingOptions
            setValue={setValue}
            loading={loading || !isValidPhoneNumber}
            onSelect={handleSubmit(onFormSubmit)}
            idealBillingOption={idealBillingOption}
          />
        )}
      </div>
    </>
  )
}

export default CompanyInfoPage
